<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑厅房"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="厅房名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入厅房名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-decorator="['hall_type',{
              rules: [
                { required: true, message: '请输入厅房类型' },
              ]
            }]"
            placeholder="请选择类型"
            allow-clear
          >
            <a-select-option value="a">
              A类
            </a-select-option>
            <a-select-option value="b">
              B类
            </a-select-option>
            <a-select-option value="c">
              C类
            </a-select-option>
            <a-select-option value="d">
              D类
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="价格">
          <a-input-number
            style="width: 80%;"
            :min="0.00"
            :max="10000000"
            :step="0.01"
            :precision="2"
            v-decorator="['hall_price', {
              rules: [
                { required: true, message: '请输入价格' },
              ]
            }]"
          /><span style="float: right;">元/小时</span>
        </a-form-item>
        <a-form-item label="图片">
          <span
            class="tip-text"
          >支持png/jpg格式照片，10M以内, 建议尺寸宽高比180:180px，最多10张
          </span>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="10"
            :multiple="true"
            :default-file-list="defaultFileList"
            @change="handleUploadImage"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="介绍">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { message: '请输入厅房描述' },
                { max: 200, message: '最多200个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import { updateHall } from '@/api/hall'

export default {
  name: 'EditHall',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'hall_edit' }),
      submitting: false,
      isAddWatermark: false,
      attachments: [], // 图片
      defaultFileList: [],
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.defaultFileList = []
      if (this.record.images && this.record.images.length > 0) {
        this.record.images.forEach((item, index) => {
          this.defaultFileList.push({
            uid: index,
            name: item.name,
            status: 'done',
            url: item.url,
            response: item.response
          })
        })
      }

      this.data = Object.assign({}, { name: this.record.name, remark: this.record.remark, hall_type: this.record.hall_type, hall_price: this.record.hall_price })
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
        this.attachments = this.defaultFileList
      })
    },

    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const editData = {
            ...values,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          updateHall(this.record.id, editData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
